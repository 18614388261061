import { SelectableValue, TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now-6M', to: 'now', display: '6 derniers mois/الأشهر الستة الأخيرة' },
  { from: 'now-1y', to: 'now', display: 'Dernière années/السنة الماضية' },
  { from: 'now-3y', to: 'now', display: 'Les 3 dernières années/السنوات 3 الماضية' },
  { from: 'now-5y', to: 'now', display: 'Les 5 dernières années/السنوات 5 الماضية' },
  { from: 'now-1y/y', to: 'now-1y/y', display: 'Année précédente/السنة السابقة' },
  { from: 'now/y', to: 'now/y', display: 'Cette année/السنة الحالية' },
];

export const monthOptions: Array<SelectableValue<number>> = [
  { label: 'Janvier', value: 0 },
  { label: 'Février', value: 1 },
  { label: 'Mars', value: 2 },
  { label: 'Avril', value: 3 },
  { label: 'Mai', value: 4 },
  { label: 'Juin', value: 5 },
  { label: 'Juillet', value: 6 },
  { label: 'Août', value: 7 },
  { label: 'Septembre', value: 8 },
  { label: 'Octobre', value: 9 },
  { label: 'Novembre', value: 10 },
  { label: 'Décembre', value: 11 },
];
